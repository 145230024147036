import { configureStore } from '@reduxjs/toolkit';

import menu from './menu/reducer';
import authUser from './auth/slice';
import sharingRequests from './sharingRequests/slice';
import household from './household/slice';
import members from './members/slice';
import paymentMethod from './paymentMethod/slice';
import attachments from './attachments/slice';
import contributions from './contributions/slice';
import providerLedgers from './providerLedgers/slice';
import enrollment from './enrollment/slice';
import { listenerMiddleware } from './listenerMiddleware';
import notifications from './notifications/slice';

export const setupStore = () => {
  const store = configureStore({
    reducer: {
      menu,
      authUser,
      sharingRequests,
      household,
      members,
      paymentMethod,
      attachments,
      contributions,
      providerLedgers,
      enrollment,
      notifications,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(listenerMiddleware.middleware),
  });

  return store;
};
